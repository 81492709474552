export const ProviderEn = () => {
  return (
    <>
      <h3>Provider</h3>
      <br />
      <div>
        <p>
          Daimler Truck AG
          <br />
          Fasanenweg 10
          <br />
          70771 Leinfelden-Echterdingen
          <br />
          Germany
          <br />
          Tel.: +49 711 8485-0
          <br />
          Email:{' '}
          <a
            href='mailto:contact@daimlertruck.com'
            className='wb-link wb-link--inline'
          >
            contact@daimlertruck.com
          </a>{' '}
          <br />
        </p>

        <p>
          <h4>Represented by the Board of Management:</h4>Karin Rådström (Chairwoman), Karl Deppen, Andreas Gorbach, Jürgen Hartwig, John O’Leary, Achim Puchert, Eva Scherer
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>
            Chairman of the Supervisory Board:
          </span>{' '}
          Joe Kaeser
          <br />
          <br />
          Commercial Register Stuttgart, No. HRB 762884
          <br />
          VAT registration number: DE 32 12 81 771
        </p>
      </div>
    </>
  );
};

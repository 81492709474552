import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {emptyProductSheetObject, IProductSheet} from "../../../interfaces/IProductSheet";
import {getSheetByNumber} from "../../../utility/FetchAPI";
import {CATEGORY_PROPS} from "../../../globals/Constants";
import {setCategoryString, showPdfInNewTab} from "../../../utility/Helper";
import { useLanguageMappings } from '../../../globals/LanguageMappingsContext';
import {useTranslation} from "react-i18next";


export const SheetPdf = () => {
    let navigate = useNavigate();
    const languageMappings = useLanguageMappings();
    
    const { t, i18n } = useTranslation();
    const mapping = languageMappings[i18n.language] || languageMappings['en'];
    let {sheetNumber, language} = useParams<{
        sheetNumber: string;
        language: string;
    }>();
    const [sheet, setSheet] = useState<IProductSheet>(emptyProductSheetObject);
    useEffect(() => {
        console.log("1111111111")
        if (sheetNumber != null) {
            console.log("222222222222")
            getSheetByNumber(sheetNumber).then((results: any) => {
                console.log("33333333333333333")
                showPdfInNewTab({ sheet: results.data, language: i18n.language, navigate, mapping ,newWindow: false,t,i18n});
            });
        }
    }, [sheetNumber, language]);

    return (<></>);
}
import { useState, useEffect } from 'react';
import '../../../globals/style/GlobalStyle.css';
import './SearchOverview.css';
import { getSearchResults } from '../../../utility/FetchAPI';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { showPdfInNewTab } from '../../../utility/Helper';
import { closeSearch } from '../../../utility/DialogHelper';
import { useLanguageMappings } from '../../../globals/LanguageMappingsContext';


// Utility function to highlight search term
const highlightText = (text, searchTerm) => {
  if (!text) return ''; // Return an empty string if text is null or undefined
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  return text.replace(regex, '<span class="highlight">$1</span>');
};

// Utility function to get the relevant portion of text
const getRelevantText = (text = '', searchTerm = '') => {
  if (!text.trim()) return '';

  const words = text.split(/\s+/); // Split by spaces (handles multiple spaces too)
  const lowerText = text.toLowerCase();
  const lowerSearchTerm = searchTerm.toLowerCase();

  // Find the index of the search term
  const searchIndex = lowerText.indexOf(lowerSearchTerm);

  if (searchIndex !== -1) {
    // Find the start and end word indices for the relevant snippet
    const wordStartIndex = lowerText.slice(0, searchIndex).split(/\s+/).length - 1;
    const wordEndIndex = wordStartIndex + lowerSearchTerm.split(/\s+/).length;

    // Include up to 5 words before and after the search term
    const startIdx = Math.max(0, wordStartIndex - 5);
    const endIdx = Math.min(words.length, wordEndIndex + 5);

    return words.slice(startIdx, endIdx).join(' ') + '...';
  }

  // If the search term is not found, return the first 10 words
  return words.slice(0, 10).join(' ') + '...';
};


export const SearchOverview = () => {
  const languageMappings = useLanguageMappings();
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState();
  const { t, i18n } = useTranslation();
  const mapping = languageMappings[i18n.language] || languageMappings['en'];
  let navigate = useNavigate();
  const location = useLocation();

  const search = (text) => {
    setSearchText(text);

    getSearchResults(text).then((results) => {
      setSearchResult(results.data);
    });
  };

  const path = (sheet) => {
    if (sheet.pdfDe == null && sheet.pdfEn == null) {
      return `/sheet/${sheet.sheetNumber.replaceAll(' ', '_')}/${i18n.language}`;
    }
    return location.pathname;
  };

  const searchButton = () => {
    getSearchResults(searchText).then((results) => {
      setSearchResult(results.data);
    });
  };

  const closeAndResetSearch = () => {
    closeSearch();
    setSearchText('');
    setSearchResult(null);
  };

  const selectSheetItem = (sheet) => {
    if (
      sheet.pdfDe === null ||
      sheet.pdfDe === '' ||
      sheet.pdfEn === null ||
      sheet.pdfEn === ''
    ) {
      setSearchText('');
      setSearchResult(null);
    }
    showPdfInNewTab({ sheet, language: i18n.language, navigate,newWindow: true,t,i18n });
  };

  return (
    <div
      id='searchContainer'
      className='notVisible searchContainerStyle foreground'
    >
      <div className='inputSearch'>
        <wb-input-control>
          <wb-input>
            <label>{t('SearchLabel')}</label>
            <input
              onChange={(e) => search(e.target.value.toString())}
              className='searchInput'
              type='text'
              value={searchText}
            />
            <wb-input-action>
              <button
                class='wb-round-button wb-round-button--small'
                type='button'
                onClick={() => {
                  setSearchText('');
                  setSearchResult(null);
                }}
              >
                <wb-icon class='wb-round-button__icon' name='close' />
                Clear input
              </button>
            </wb-input-action>
          </wb-input>
        </wb-input-control>
      </div>
      <div class='searchButton'>
        <button
          type='button'
          aria-label='Search Button'
          class='wb-button wb-button--primary wb-button--large wb-button--icon-only'
          onClick={searchButton}
        >
          <wb-icon
            class='wb-button__icon'
            aria-hidden='true'
            name='search'
          ></wb-icon>
          Search
        </button>
      </div>
      <wb-button-control
        variant='tertiary'
        class='backButton wb-type-button-secondary'
        onClick={closeAndResetSearch}
      >
        <button type='button'>{t('BackToOverview')}</button>
      </wb-button-control>
      <div className='textWhite wb-type-label textSpace'>
        {searchText != '' && (
          <p>{`${t('SearchResultPart1')} ${searchResult?.length} ${t(
            'SearchResultPart2'
          )}`}</p>
        )}
      </div>
      <div class='cardContainer'>
        {searchResult?.map((productSheet) => {
          const langKey = `additionalInfo${i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)}`;
          const rawText = productSheet[langKey] || '';
          const relevantText = getRelevantText(rawText, searchText);
          const highlightedText = highlightText(relevantText, searchText);

          return (
            <div
              class='wb-padding-s searchCard'
              onClick={() => selectSheetItem(productSheet)}
            >
              <wb-card interactive variant='dark-grey' class="hydrated" style={{ border: "none" }}>
              <Link
                      onClick={() =>
                        showPdfInNewTab({ productSheet, language: i18n.language, navigate, mapping,newWindow: true,t,i18n })
                      }
                      to={path(productSheet)}
                      type='button'
                      className='wb-button wb-type-copy-strong'
                    >
                
                <div class="totalcontentRow">
                    <div class="left-column">
                        <div class="text wb-type-hint firstColumnHeader">{t("Sheet")}</div>
                        <div 
                          className="wb-type-heading-m firstColumnText" 
                          // style={{ fontSize: "2.125rem" }}
                        >
                          {productSheet?.sheetNumber}
                        </div>
                    </div>
                    <div class="right-column">
                      
                    <div class="wb-type-hint secondColumnHeader" 
                    // style={{ fontSize: "2.125rem" }}
                    >
                      {t("Description")}</div>
                    <div class="wb-type-label secondColumnText"
                      // style={{ fontSize: "2.125rem" }}
                          dangerouslySetInnerHTML={{
                            __html: (() => {
                              switch (i18n.language) {
                                case "de":
                                  return highlightText(productSheet?.titleDe || '', searchText);
                                case "pt":
                                  return highlightText(productSheet?.titlePt || '', searchText);
                                default:
                                  return highlightText(productSheet?.titleEn || '', searchText); // Default to English
                              }
                            })(),
                          }}
                        ></div>

                          <div class="text wb-type-label" dangerouslySetInnerHTML={{
                              __html: highlightedText,
                            }}></div>

                        {/* <div class="text wb-type-label ">{t(productSheet?.category)}</div> */}

                        {/* <div class="text wb-type-label">{(() => {
                            switch (i18n.language) {
                              case "de":
                                return  highlightText(productSheet?.additionalInfoDe, searchText);
                              case "pt":
                                return highlightText(productSheet?.additionalInfoPt, searchText);
                              default:
                                return highlightText(productSheet?.additionalInfoEn, searchText); // Default to English
                            }
                          })()}</div> */}

                        <div class=""><wb-icon name='chevron-right' class='cardIcon'></wb-icon></div>
                    </div>
                </div>

                {/* <a class='wb-type-copy-strong'> */}
                {/* <div class="contentRow">
                    <div class="firstColumn">
                      <div class="wb-type-hint firstColumnHeader">{t("Sheet")}</div>
                      <div class="wb-type-heading-m firstColumnText">
                        {productSheet?.sheetNumber}
                      </div>
                    </div>
                    <hr class="verticalLineCard" />
                    <div class="secondColumn">
                      <div class="wb-type-hint secondColumnHeader">{t("Description")}</div>
                      <div class="wb-type-label secondColumnText">
                          {(() => {
                            switch (i18n.language) {
                              case "de":
                                return productSheet?.titleDe;
                              case "pt":
                                return productSheet?.titlePt;
                              default:
                                return productSheet?.titleEn; // Default to English
                            }
                          })()}
                        </div>
                        <div className='secondColumnDetails'>
                        {(() => {
                            switch (i18n.language) {
                              case "de":
                                return productSheet?.additionalInfoDe;
                              case "pt":
                                return productSheet?.additionalInfoPt;
                              default:
                                return productSheet?.additionalInfoDe; // Default to English
                            }
                          })()}
                        </div>
                    </div>
                  </div> */}
                  {/* <wb-icon name='chevron-right' class='cardIcon'></wb-icon> */}
                {/* </a> */}
                </Link>
              </wb-card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import {IProductSheet} from '../interfaces/IProductSheet';
import {closeCategoriesMenu, closeSearch} from './DialogHelper';
import {parse} from 'date-format-parse';
import { getSheetByNumber } from './FetchAPI';
import { useLanguageMappings } from '../globals/LanguageMappingsContext';
import {useTranslation} from "react-i18next";

export const parseDate = (dateString: string | Date): Date => {
    if (dateString instanceof Date) {
        return dateString
    }
    if (dateString.includes('/')) {
        return parse(
            dateString,
            'M/D/YY, h:m A'
        )
    }

    return parse(
        dateString.replaceAll(',', ''),
        'DD.MM.YY HH:mm'
    )
}

export const setCategoryString = (category: string) => {
    if (category === 'Engine Oil' || category === 'EngineOil' || category === 'Óleo de motor') {
        return 'Motoröl';
    } else if (category === 'Brake Fluid' || category === 'BrakeFluid' || category === 'Fluido de freio' ) {
        return 'Bremsflüssigkeit';
    } else if (category === 'Fuel' || category === 'Combustível') {
        return 'Kraftstoff';
    } else if (category === 'Gear Oil' || category === 'GearOil' || category === 'Óleo de transmissão') {
        return 'Getriebeöl';
    } else if (category === 'E-Drive' || category === 'Sistemas de propulsão elétrica') {
        return 'E-Antriebssysteme';
    } else if (category === 'Grease' || category === 'Graxa') {
        return 'Fett';
    } else if (category === 'Hydraulic Fluid' || category === 'HydraulicFluid' || category === 'Óleo hidráulico') {
        return 'Hydrauliköl';
    } else if (
        category === 'Preservation Agent' ||
        category === 'PreservationAgent' || category === 'Conservantes'
    ) {
        return 'Konservierungsmittel';
    } else if (
        category === 'Refrigerator Oil' ||
        category === 'RefrigeratorOil' || category === 'Óleo para compressor'
    ) {
        return 'Kompressoröl';
    } else if (
        category === 'Steering Gear Oil' ||
        category === 'SteeringGearOil' || category === 'Óleo para caixa de direção'
    ) {
        return 'Lenkgetriebeöl';
    } else if (
        category === 'NOx Reducing Agent' ||
        category === 'NOxReducingAgent' ||
        category === 'AdBlue' || category === 'Agente de redução de NOx'
    ) {
        return 'NOx-Reduktionsmittel';
    } else if (
        category === 'Korrosions-/Frostschutz' ||
        category === 'Korrosions- /Frostschutz' ||
        category === 'Korrosions- / Frostschutzmittel' || category === 'Agentes anticorrosivos / agente anticongelante'
    ) {
        return 'Frostschutz';
    } else if (category === 'Coolant' || category === 'Agentes anticorrosivos / agente anticongelante') {
        return 'Frostschutz';
    } else if (category === 'Refrigerant' || category === 'Agente refrigerante') {
        return 'Kältemittel';
    } else if (category === 'edAC-Oil' || category === 'Óleo edAC') {
        return 'edAC-Öl';
    } else if (
        category === 'E-Engine Oil' ||
        category === 'E-Motor-Oil' || category === 'E-Óleo para motor'

    ) {
        return 'E-Motoröl';
    } else if (category === 'Axle Oil' || category === 'Óleo do eixo') {
        return 'Achsenöl';
    } else if (
        category === 'Windshield Washer Fluid Concentrate' ||
        category === 'WindshieldWasherFluidConcentrate' ||
        category === 'Scheibenwaschmittel-Konzentrat' ||
        category === 'Windshield' || category === 'Limpa-vidros concentrado'
    ) {
        return 'Scheibenwaschmittel-Konzentrat';
    } else return category;
};

export async function showPdfInNewTab(
    { sheet, language, navigate, mapping, newWindow = true, languageMappings =null, t=null, i18n=null }: 
    { sheet: IProductSheet; language: string; navigate: any; mapping: any; newWindow?: boolean; languageMappings?: any; t?: any; i18n?: any; }
) {
    let path = '/';
    // mapping = languageMappings[i18n.language] || languageMappings['en'];

    try {
        console.log("30303030")
        // Validate inputs
        if (!sheet || !language || !mapping) {
            console.error('Invalid input parameters for showPdfInNewTab');
            return;
        }

        console.log("3a3a3a3a3a3a3")

        // Fetch updated sheet data if PDFs are marked as "exists"
        if (sheet?.pdfDe === "exists" && sheet?.pdfEn === "exists" && sheet?.pdfPt === "exists") {
            sheet = await getSheetData(sheet);
        }

        console.log("Updated sheet data:", sheet);
        console.log("Mapping used:", mapping);
        console.log("44444444444444444444444")

        // Ensure all required PDFs are available
        if (sheet?.pdfDe && sheet?.pdfEn && sheet?.pdfPt) {
            console.log("5555555555555555")
            // Determine file name and base64 PDF data
            const fileName = sheet[mapping.title] || `PDF_${sheet.sheetNumber}_${language}`;
            const base64Data = sheet[mapping.pdf];

            // Construct link source
            const linkSource = `data:application/pdf;base64,${encodeURI(base64Data)}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = linkSource;
            downloadLink.download = fileName;

            if (!navigator.userAgent.toLowerCase().includes('android')) {
                const pdfWindow = newWindow ? window?.open(linkSource) : window;
                if (pdfWindow) {
                    pdfWindow.document.write(`
                        <html>
                            <head>
                                <title>${fileName}</title>
                                <style>
                                    body { margin: 0; }
                                    iframe { border-width: 0; }
                                </style>
                            </head>
                            <body>
                                <embed 
                                    width="100%" 
                                    height="100%" 
                                    src="${linkSource}#toolbar=0&navpanes=0&scrollbar=0">
                                </embed>
                            </body>
                        </html>
                    `);
                    pdfWindow.history.pushState({}, fileName, `/pdf/${sheet.sheetNumber}/${language}`);
                }
                console.log("66666666666666666666666")
            } else {
                console.log("7777777777777777")
                // Trigger download for Android devices
                downloadLink.click();
            }
            
        } else {
            console.log("88888888888888888888888")
            // Navigate to fallback path if PDFs are not available
            path = `/sheet/${sheet.sheetNumber?.replaceAll(' ', '_')}/${language}`;
            closeCategoriesMenu();
            closeSearch();
            navigate(path);
        }
    } catch (error) {
        console.error('Error in showPdfInNewTab:', error);
    }
}

// Fetch updated sheet data
export function getSheetData(sheet: IProductSheet): Promise<IProductSheet> {
    return getSheetByNumber(sheet.sheetNumber)
        .then((results: any) => results.data as IProductSheet)
        .catch((error) => {
            console.error('Error fetching sheet data:', error);
            throw error;
        });
}

